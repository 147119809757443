<template>
  <div>
    <h2>Вывоз отходов</h2>
    <el-date-picker
                  v-model="date"
                  type="dates"
                  :clearable="true"
                  placeholder = "Выберите дату"
                  value-format="yyyy-MM-dd"
                  class="startTimeBox"
                  @change="changeData"
    ></el-date-picker>
    <el-row v-for="dt in date" v-bind:key="dt" style="margin: 5px;">
      {{ dt.split('-')[2]+"."+dt.split('-')[1]+"."+dt.split('-')[0] }}
    </el-row>
    <el-divider></el-divider>

    <el-button type="primary" @click="openAddWasteModal" :disabled="date == ''"
      >Добавить отход</el-button
    >
    <el-divider></el-divider>
    <el-table stripe :data="wastes">
      <el-table-column label="Наименование отхода" prop="wasteName"></el-table-column>
      <el-table-column label="Код отхода" prop="wasteCode"></el-table-column>
      <el-table-column label="Степень опасности" prop="dangerDegreeClassifierDTO.name"></el-table-column>
      <el-table-column> 
        <template slot-scope="item">
        <el-button @click="dropLine(item.$index)" type="primary" style="float: left" 
              >Удалить
        </el-button> 
      </template>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    
    <el-tree
      :data="data"
      show-checkbox
      node-key="id"
      ref="tree"
      :props="defaultProps"
      style="font-weight: bold;"
    >
    </el-tree>
    <el-divider></el-divider>
    <el-button type="primary" @click="showData" :disabled="((date!='' ? new Date(date[date.length-1].split('-')[0],date[date.length-1].split('-')[1]-1,date[date.length-1].split('-')[2]): new Date(new Date().getFullYear()-1,0,1))<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234  && this.userId!=292 && this.userId!=245 && this.userId!=14)) ">Отобразить данные</el-button>

    <custom-modal 
      @addWasteToTable="addWasteToTable"
      title="Добавить отход"
      :visible="showModal === 'disposalAddWaste'"
    >
      <disposal-add-waste-form :action="date"></disposal-add-waste-form>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
import DisposalAddWasteForm from "@/components/ecologist/disposal/disposalAddWasteForm";
import Cookies from "js-cookie";
export default {
  
  name: "disposalMain",
  components: { DisposalAddWasteForm, CustomModal },
  data() {
    return {
      userId:Cookies.get('userId') ,
      date: "",
      resp:[],
      wastes:[],
      addedWastes: [],
      data: [
        {
          id: 1,
          name: "Предприятие",
          children: [
          ],
        }
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },

  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    dropLine(index)
    {
      this.wastes.splice(index, 1);
      this.getWasteOrgs();
    },
    async addWasteToTable(waste)
    {
      this.wastes.push(waste);
      this.getWasteOrgs();
    },
    async getWasteOrgs()
    {
      this.data= [{id: 1,name: "Предприятие",children: [],}];
      this.wastes.forEach(async waste => {
        let t= 0;
        await this.getUnitList(4,waste.ss01DictionaryId).then((response) => {
          response.data.forEach((value1) =>
          {
            t= 0;
            this.data[0].children.forEach((value2) => {
              if (value2.id == value1.id)
                {
                t = -1;
                }
            })
          if (t==0)
          this.data[0].children.push(value1);
          })
          waste.orgs=response.data;
        });
      });
      /*console.log(this.wastes);*/
    },
    changeData()
    {console.log(this.date);
      if((new Date(this.date[this.date.length-1].split('-')[0],this.date[this.date.length-1].split('-')[1]-1,this.date[this.date.length-1].split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=292 && this.userId!=245 && this.userId!=14)))
        this.message("Внимание! Внесение данных за "+new Date(this.date[this.date.length-1]).getFullYear()+" год ограничено. Закрыт отчетный период", "error", true);
      //console.log("changeData");
      this.wastes = [];
      this.data= [{
          id: 1,
          name: "Предприятие",
          children: []
        }];
    },
    openAddWasteModal() {
      this.$store.dispatch("setShowModal", "disposalAddWaste");
    },

    showData() {
      /*console.log("Main");
      console.log(this.wastes);
      console.log(this);
      console.log("Main");*/
      this.$router.push({ path: "selected-waste", query: {wastes: this.wastes, chekbranches:this.$refs.tree.getCheckedNodes(), date:this.date} });
    },

    async getUnitList(unitType, unitId) {
      return await this.$store.dispatch("organizationUnitsForSelectDatalimit", {
        unitType: unitType,
        unitId: unitId,
        unitdate: this.date[this.date.length-1]
      });
    },
  },

 async mounted() {
    
   // this.$store.dispatch("showDisposalWasteData");
   /* await this.getUnitList(1, 0).then((response) => {
      response.data.forEach(element => element.children=[]);
      this.data = response.data;
      this.data.push({"id": 0, "name": "Пропустить", "children": []});
      });
    for(let i =0;i<this.data.length;i++){
      await this.getUnitList(2, this.data[i].id).then((response) => {
        response.data.forEach(element => element.children=[]);
        console.log(response.data);
        this.data[i].children=response.data;
        this.data[i].children.push({"id": 0, "name": "Пропустить", "children": []});        
        });
      for(let j =0;j<this.data[i].children.length;j++){
        await this.getUnitList(3, this.data[i].children[j].id).then((response) => {
          this.data[i].children[j].children=response.data;
        });
      }
    }
    this.data1[0].children=this.data;*/
  },
};
</script>

<style scoped></style>
